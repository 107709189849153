@import 'src/assets/globalStyles/variables';

.body {
  position: relative;

  .input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px;
    background: #121b7800;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    max-width: 200px;

    span {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    .icon {
      width: 20px;
      height: 20px;
      border: 1px solid rgba(255, 255, 255, 0);
      margin-right: 10px;
      border-radius: 100%;
    }
  }

  &:not(:global(._isDisable)):hover {
    cursor: pointer;
    .title {
      background: #1c248e;
    }
  }

  & :checked {
    ~ .title {
      background: #121a78;
      .icon {
        border: 1px solid #58BF00;
      }
    }
  }

  &:global(._isDisable) {
    .title {
      opacity: 0.5;
    }

    &:hover {
      cursor: default;
    }
  }
}

@media all and (max-width:1000px) {
  .body :checked ~ .title {
    background: #121b7800;
    max-width: 95%;

    &:after {
      content: '';
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-55%);
      height: 24px;
      width: 24px;
      background: url(./../../assets/icons/mark.svg) center no-repeat;
    }

    .icon {
      border: none;
    }
  }
}
