$base: #4553b8;
$blue: #00b3eb;
$white: #ffffff;
$black: #000000;
$grey: #f3f3f3;
$dark-grey: #dadada;
$tr: #ff000000;
$dark-base: rgba(38, 47, 159, 1);

$desktop: 1920px;
$laptop: 1366px;
$tablet: 1140px;
$phone: 768px;

@mixin laptop {
  @media all and (max-width: $desktop) {
    @content;
  }
}


@mixin tablet {
  @media all and (max-width: $laptop){
    @content;
  }
}

@mixin phone {
  @media all and (max-width: $tablet) {
    @content;
  }
}
