@import "src/assets/globalStyles/variables";

.burger {
  width: 18px;
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  span {
    height: 2px;
    width: 100%;
    background: #ffffff;
    position: relative;
  }

  &:global(._isOpen) {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        top: -50%;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}
