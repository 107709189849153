@import 'src/assets/globalStyles/variables';

.body {
  background: $dark-base;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .menu {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .menuContainer {
      transition: left 0.3s;
      height: 100%;
      overflow-y: scroll;
      width: 100%;
    }

    .menuBody {
      position: relative;
      z-index: 1;
      height: 100vh;
      padding-top: 40px;
      width: 455px;
      margin-left: 30%;

      .menuOptions {
        margin-bottom: 27px;

        & > label {
          margin-right: 16px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .menuExchange {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 79px;

        .column {
          &:first-of-type {
            margin-right: 20px;
          }

          &:last-of-type {
            margin-left: 20px;
          }

          .columnHeader {
            margin-bottom: 16px;

            svg {
              fill: #b6bbff;

              &:hover {
                cursor: pointer;

                fill: #fff;
              }
            }
          }

          .columnItems {
            max-height: 50vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 8px;
            max-width: 206px;

            & > div {
              margin-bottom: 12px;
            }

            &:global(._notFound) {
              overflow: visible;
              .notFound {
                height: 50vh;
              }
            }
          }

          .notFound {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              font-size: 12px;
              line-height: 16px;
              display: block;
              margin-bottom: 18px;
              text-align: center;
            }

            .icon {
              width: 26px;
            }
          }

          &:global(._isTransparent) {
            opacity: 0.2;
          }
        }
      }
    }

    .mobile {
      position: relative;
      z-index: 1;
      display: none;
    }
  }

  .menuBgLeft {
    width: 318px;
    height: 318px;
    left: -11%;
    top: -14%;
  }

  .menuBgRight {
    width: 188px;
    height: 188px;
    right: -100px;
    top: 67%;
  }

  .screen {
    position: relative;
    background: #4553b8;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .screenContent {
      padding-top: 126px;
      // max-width: 567px;
      max-width: 455px;
      // padding-left: 52px;
      position: relative;
      z-index: 2;
      width: 100%;
      margin: 0 auto;
    }

    .menuBgbottom {
      right: -18%;
      bottom: -35%;
      width: 696px;
      height: 696px;
    }
  }

  .mobileSearch {
    display: none;
  }

  &:global(.isMobile) {
    overflow: hidden;
    height: 100vh;
  }
}

.circleBg {
  position: absolute;
  background: #4553b8;
  opacity: 0.5;
  box-shadow: 0px 0px 50px 25px rgba(255, 255, 255, 0.15);
  border-radius: 100%;
  z-index: 0;
}

@include tablet {
  .body {
    .menu {
      .menuBody {
        margin-left: 15%;

        .menuExchange {
          .column {
            .columnItems {
              max-height: 365px;
            }
          }
        }
      }
    }
  }
}

@include phone {
  .body {
    grid-template-columns: 1fr;
    height: auto;
    overflow-x: hidden;
    overflow-y: visible;

    .menu {
      padding: 0px;
      padding-top: 26px;
      overflow: visible;

      .menuContainer {
        display: none;
        .menuBody {
          display: none;
        }
      }

      .mobile {
        display: block;

        .container {
          padding-left: 26px;
          padding-right: 26px;
        }

        .swap {
          padding-left: 26px;
          padding-right: 26px;
        }
      }
    }

    .screen {
      display: none;
    }

    .mobileSearch {
      display: block;
      position: fixed;
      height: 100vh;
      width: 100vw;
      background: #262f9f;
      z-index: 999;

      .column {
        height: 90%;

        .columnItems {
          height: 90%;
        }
      }
    }
  }
}
