@import "src/assets/globalStyles/variables";

.column {
  padding-top: 16px;
  height: 100%;
  margin: 0 29px;

  .columnHeader {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    line-height: 24px;

    svg {
      fill: #b6bbff;

      &:hover {
        cursor: pointer;

        fill: #fff;
      }
    }
  }

  .search {
    width: 90%;
    margin: 0 auto;
  }

  .columnItems {
    height: 70%;
    width: 90%;
    margin: 0 auto;
    overflow-y: scroll;
    overflow-x: hidden;

    & > div {
      margin-bottom: 12px;
    }

    &:global(._notFound) {
      display: none;
    }
  }

  .notFound {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 16px;
      display: block;
      margin-bottom: 18px;
      text-align: center;
    }

    .icon {
      width: 26px;
    }
  }

  &:global(._isTransparent) {
    opacity: 0.2;
  }
}
