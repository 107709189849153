@import 'src/assets/globalStyles/variables';

.swap {
  margin-bottom: 60px;

  .swapTitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 29px;
    text-transform: uppercase;
  }

  .swapPrompt {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 28px;
  }

  .swapCopy {
    border-bottom: 1px solid #b6bbff;
    padding: 12px;
    margin-bottom: 15px;

    .swapField {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        background: rgba(0, 0, 0, 0);
        font-size: 20px;
        line-height: 24px;
        width: 90%;
      }

      .copyBtn {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;
        }

        &:hover {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .swapCopuBtn {
    display: none;
  }

  .swapNote {
    font-size: 12px;
    line-height: 16px;
    position: relative;
    padding-left: 14px;

    strong {
      font-weight: 500;
    }

    &:after {
      content: '\2022';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@include phone {
  .swap {
    background-color: $base;
    padding-top: 24px;
    padding-bottom: 60px;
    text-align: center;
    .swapCopy {
      background: #121a78;
      border-radius: 8px;
      border: none;
      .swapField {
        input {
          text-align: center;
          width: 100%;

          &:hover {
            cursor: pointer;
          }
        }
        .copyBtn {
          display: none;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .swapCopuBtn {
      display: block;
      margin-bottom: 36px;
    }

    .swapNote {
      &:after {
        display: none;
      }
    }
  }
}
