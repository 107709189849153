@import "src/assets/globalStyles/variables";

.body {
  position: relative;
  display: inline-block;

  .input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .title {
    font-size: 12px;
    line-height: 16px;
    color: #b6bbff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 4px 16px;
    border: 1px solid #b6bbff;
    border-radius: 8px;
  }

  &:hover {
    .title {
      border: 1px solid #ffffff;
      cursor: pointer;
      color: #ffffff;
    }
  }

  & :checked {
    ~ .title {
      background: #ffffff;
      color: #4553b8;
      border: 1px solid #ffffff;
    }
  }
}
