@import "src/assets/globalStyles/variables";

.input {
  display: flex;
  flex-direction: column;
  width: 45%;

  .header {
    .title {
      font-size: 12px;
      line-height: 16px;
      color: #b6bbff;
      margin-bottom: 30px;
    }

    .select {
      display: none;
    }
  }

  .max {
    font-size: 12px;
    line-height: 16px;
    color: #b6bbff;
  }

  .fieldBody {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding: 12px;

    .field {
      font-size: 24px;
      line-height: 24px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
    }

    .currency {
      margin-left: 10px;
      font-size: 12px;
      width: 42px;
      text-transform: uppercase;
      overflow: hidden;
    }
  }

  &:global(._isError) {
    .fieldBody{
      border-color:#FF7373 ;
    }
      input{
        color: #FF7373;
      }
  }
}

@include tablet {
  .input {
    width: 40%;
  }
}

@include phone {
  .input {
    width: 100%;
    
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;

      .title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
      }

      .select {
        display: flex;
        align-items: center;

        .icon {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          margin-left: 8px;
          margin-right: 14px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .fieldBody {
      max-width: 100%;
    }
  }
}
