@import "src/assets/globalStyles/variables";

.converter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;


  .arrow {
    width: 16px;
    align-self: center;
  }

}
.error {
  color: #FF7373;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  svg{
    margin-right: 14px;
  }

}

.listError{
  color: #FF7373;
}

@include phone {
  .converter {
    flex-direction: column;

    & > div {
      &:first-child {
        margin-bottom: 46px;
      }
    }

    .arrow {
      display: none;
    }
  }
}
