@import "src/assets/globalStyles/variables";

.info {
  .infoTitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .infoText {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .infoBtn {
  }
}

@media screen and (max-width:1366px){
  .info {
    text-align: center;
    margin-bottom: 60px;
  }
}
