@import "src/assets/globalStyles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  grid-area: header;

  .logo {
    div {
      display: flex;
      align-items: center;
    }
  }
}


