@import './reset';
@import './variables';

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Light.ttf');
  src: local('../fonts/Chivo-Light'),
    url('../fonts/Chivo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Regular.ttf');
  src: local('../fonts/Chivo-Regular'),
    url('../fonts/Chivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Bold.ttf');
  src: local('../fonts/Chivo-Bold'),
    url('../fonts/Chivo-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo-Black.ttf');
  src: local('../fonts/Chivo-Black'),
    url('../fonts/Chivo-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
  transition: 0.2s;
}

body {
  color: #ffffff;
  font-family: 'Chivo';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $tr;
}

::-webkit-scrollbar-thumb {
  background: #121a78;
  border-radius: 2px;
}

@include phone {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: $tr;
  }

  ::-webkit-scrollbar-thumb {
    background: #121a78;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}