@import "src/assets/globalStyles/variables";

.menuContacts {
    margin-bottom: 27px;

    a {
      color: #b6bbff;
      margin-right: 28px;
      font-size: 12px;
      line-height: 16px;
      position: relative;

      &:before {
        content: "\2022";
        position: absolute;
        top: 0;
        right: 0px;
        transform: translateX(16px);
        pointer-events: none;
      }

      &:last-of-type {
        margin-right: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .menuBottom {
    & > div {
      display: flex;
      align-items: center;
    }
  }

  @include phone {
    .menuContacts {
      text-align: center;
    }

    .menuBottom {
      & > div {
        justify-content: center;
      }

      margin-bottom: 28px;
    }
  }