@import "src/assets/globalStyles/variables";

.search {
  .wrapper {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    .title {
      font-size: 20px;
      line-height: 24px;
      margin-left: 11px;
    }

    .body {
      padding-bottom: 8px;
      border-bottom: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      animation-name: width;
      animation-duration: 0.1s;
      animation-direction: normal;

      .input {
        width: 90%;
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  .mobile {
    display: none;
  }
}

@keyframes width {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@include phone {
  .search {
    margin-bottom: 32px;

    .wrapper {
      display: none;
    }

    .mobile {
      display: block;
      position: relative;
      padding: 11px 3px;

      border-bottom: 1px solid #b6bbff;
      width: 100%;

      .icon {
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
      }

      .input {
        background: rgba(0, 0, 0, 0);
        width: 100%;
        padding-left: 25px;
      }
    }
  }
}
