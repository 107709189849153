@import 'src/assets/globalStyles/variables';

.btn {
  font-size: 12px;
  line-height: 16px;
  color: #4553b8;
  padding: 12px 20px;
  background: #ffffff;
  border-radius: 20px;

  &:global(._isDotted) {
    color: #b6bbff;
    background-color: #4552b800;
    border: 1px dashed #b6bbff;
  }

  &:hover {
    background: #b6bbff;
    color: #ffffff;
    cursor: pointer;
  }
}
